@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans:300);
.user-body {
   padding-top: 35px;
}

.main {
    padding-left: 4% !important;
    padding-right: 4% !important;
}
.user-info {
    display: inline-block;
    width: 100%;
    background-color: #F3F3F3;
}
    
#about {
    height: 95px;
    background-color: #F3F3F3
}
.center {
    display: block;
    margin: 0 auto;
}
#head-img {
    height: 250px;
    background-color: #D6D6D6;
    border-bottom: 0px;
    border-left: 9px solid white;
    border-right: 9px solid white;
    border-top: 9px solid white;
}
#profile img {
    width: 180px;
    height: 180px;
    border: 4px solid white;
}
#profile {
    top: -95px;
}

#profile-options {
    background-color: #073763;
    height: 40px;
    color: white;
}


.col-sm-2 {
    height: 40px;
    border-left: 1px solid white;
    text-align: center;
}

.sidebar {
	height: 100vh;
    width: 220px;
    float: right;
    position: relative;
    padding-left: 50px;
}

/* Sidebar links */
.sidebar a {
  display: block;
  color: #073763;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 5px;
  text-decoration: underline;
}
.sidebar h4 {
  display: block;
  color: #073763;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 5px;
}

.sidebar a.active {
  color: black;
}
.sidebar a:hover:not(.active) {
  color: white;
}
.svg-inline--fa.fa-volume-up.fa-w-18 {
    width: 60%;
    color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    display: block;
    margin: auto;
}
.svg-inline--fa {
    width: 40%
}
.social:hover {
    transform: scale(1.15);
}
div.content {
    margin-right: 260px;
    padding: 1px 16px;
}
.icon {
    background-color: #073763;
    border-radius: 20px;
    text-align: center;
}
.social {
    background-color: #2a2e2c;
    border-radius: 50%;
    color: white;
    display:inline-block;
    margin-right: 5px;
    margin-left: 5px;
    transition: all .2s ease-in-out;
    width: 50px;
    height: 50px;
}

#icon-row {
    padding-left: 30px;
    padding-right: 30px;
}
.col-md-3 {
    margin-left: 30px;
    margin-right: 30px;
}
#blog {
    background-color: #073763;
}
@media screen and (max-width: 1200px) {
    .col-md-3 {
        margin-right: 15px;
        margin-left: 15px;
    }
}

@media screen and (max-width: 1000px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}

    #profile img {
        width: 150px;
        height: 150px;
        border: 3px solid white;
    }
    #profile {
        top: -70px;
    }
    #icon-row {
        padding-left: 5px;
        padding-right: 5px;
    }
    div.content {
      margin-right: 0px;
      padding: 0x;
    }
    .icon {
        width: 100%;
    }
    #head-img {
        height: 200px;
    }

}
@media screen and (max-width: 800px) {
    #about {
        height: 75px;
    }
    .user-body {
        padding-top: 54px;
    }
        #profile img {
            width: 130px;
            height: 130px;
        }
        #profile {
            top: -65px;
        }
        .col-sm-2 p {
            padding-left: 0px;
            padding-right: 0px;
            font-size: 15px;
        }
        .col-md-3 {
            margin-right: 0px;
            margin-left: 0px;
        }
    }

@media (max-width: 576px) {
   
    #profile img {
        width: 95px;
        height: 95px;
        border: 3px solid white;

    }
    .svg-inline--fa.fa-volume-up.fa-w-18 {
        width: 50%;
        color: white;
    }
    #profile {
        height: auto;
        top: -35px;
        border: 0px;
        padding-left: 0px;
    }
    #profile p {
        font-size: 8px;
    }

    #head-img {
        height: 180px;
        border: 0px;
    }
    .user-body {
        padding-top: 55px;
    }
    .name {
        padding-top: 50px;
    }
    .col-sm-2 {
        padding-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .col-sm-2 p {
        font-size: 14.5px;
    }
    .center {
        display: block !important;
        margin: 0 auto !important;
    }
    #follow {
        border: 0px;
        margin-left: 0px;
    }
    #follow p {
        font-size: 12px;
    }
}

.like-text {
    margin-left: 5px;
    margin-bottom: 0px;
}
.flex-spacing {
    margin-bottom: 20px;
    margin-top: 50px;
}
.circle {
    border-radius: 50%;
}

#head-img {
       
}

.col-sm-6 {
    margin-bottom: 20px;
}
.name h3 {
    display: inline-block;
    margin-right: 30px;
}
.name {
    padding-bottom: 15px;
}
.name a {
    margin-bottom: 15px;
}
.label {
    font-weight: bold;
}
#search-button {
  width: 25px;
  height: 25px;
  border: 0px;
  background-color: transparent;
}
    
#search-button svg {
  width: 25px;
  height: 25px;
}
.post-info * {
    display: inline-block;
    padding-right: 5px;
}
.post {
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,1);
    padding: 7px;
    padding-left: 10px;
    margin-bottom: 10px;
}
.delete svg {
    margin-left: 18px;
    display: inline-block;
}
.likes svg {
    display: inline-block;
}
.likes {
    margin-left: 30px;
}
.post-image-content {
    margin-bottom: 12px;
}
.post-image {
    border-radius: 10px;
}

.post-description {
    padding-left: 0px !important;
}
.likes {
    margin-right: 40px;
}

.post-profile-img {
    width: 50px;
    height: 50px;
}

.number-text {
    border-radius: 10px; 
    background-color: gray; 
    color: white; 
    padding: 3px;
    display: inline;
}

.side-list * {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 20px;
}

#brand {
    max-width : 410px; 
    height: auto;
}

@media (max-width: 576px) {
    #brand {
        width: 220px !important;
        height: auto !important;
        padding: 5px !important;
    }
}

.navbar {
    background-color: #4185F2;
}


/* Sticky footer position and size
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

.footer {
  position: absolute;
  bottom:0px;
  width: 100%;
  /* Set the fixed height of the footer here */
  height:0;
}


footer a:hover, footer a:focus {
  color: #aaa;
  text-decoration:none;
  border-bottom:1px dotted #999;
}

footer .form-control {
    background-color: #1f2022;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: none;
    resize: none;
    color: #d1d2d2;
    padding: 0.7em 1em;
}

.copyright {
  padding: 10px 0;
  background-color: #2c2c2c; }
  .copyright span,
  .copyright a {
    color: #ffffff;
    transition: all 0.3s linear; }
  .copyright a:hover {
    color:#007bff; }

.copyright-menu ul {
  text-align: right;
  margin: 0; }

.copyright-menu li {
  display: inline-block;
  padding-left: 20px; }

.Account_limitHeight__3HDYl {
    min-height: 80vh;
}

.Account_widthLimit__1oLMY {
    max-width: 400px;
}
.Home_content__-VV4m {
    position: relative;
  }
  
  .Home_content__-VV4m::after {
    content: "";
    background: url('https://risenetwork.net/storage/app/media/RN_Landingpage.png') center;
    background-size: cover;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }

.Home_buttonSpace__3npjK {
    margin: 10px;
}
.headerBlock {
    height: 60vh;
    width: 100%;
}

.water {
    background-image: url('https://risenetwork.net/storage/app/media/image_darker.jpg'); background-attachment: fixed;
    background-position: center center;
	background-size: cover;
}

.trees {
    background-image: url('https://risenetwork.net/storage/app/media/tree-dark.jpg'); background-attachment: fixed;
    background-size: cover;
    background-position: center center;
}

.clouds {
    background-image: url('https://risenetwork.net/storage/app/media/RN_Landingpage.png'); background-attachment: fixed;
    height: 40vh;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    z-index: -1;  
}

.building {
    background-image: url('https://risenetwork.net/storage/app/media/RN_Landingpage.png'); background-attachment: fixed;
    height: 45vh;
    background-size:inherit;
    background-position:bottom;
    opacity: 0.8;
    z-index: -1;  
}

.advertImage {
    width: 100%;
    height: 400px;
    object-fit: fill;
}

.bg-darker {
    background-color: #222629;
}

.img-fill {
    width: 100%;
    height: auto;
}

.bulletin {
    /* background-image: url('https://cdn.pixabay.com/photo/2012/12/24/08/39/backdrop-72250_960_720.jpg'); background-attachment: fixed; */
    background-color: black;
    background-position: center center;
 background-size: contain;
}

html{
    scroll-behavior: smooth;
}

.iframe-container {
    text-align:center;
    width: 65%;
}

.Cover {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
.glass{
	/* background styles */
	position: relative;
	display: inline-block;
	padding: 15px 25px;
	background-color:#4085f1; /*for compatibility with older browsers*/
	background-image: linear-gradient(#15a2b8);

	/* text styles */
	text-decoration: none;
	color: rgb(255, 255, 255);
	font-size: 25px;
	font-family: 'Alegreya Sans', sans-serif;
	font-weight: 100;

    border-radius: 3px;
    box-shadow: 0px 1px 4px -2px #333;
    text-shadow: 0px -1px #333;
}

.glass:after{
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: calc(100% - 4px);
	height: 50%;
	background: linear-gradient(rgba(105, 182, 233, 0.8), rgba(255,255,255,0.2));
}

.glass:hover{
	background: linear-gradient(rgb(255, 255, 255),#4085f1);
}

div.center {
    margin-left: auto;
    margin-right: auto;
    display: table;
    color:  white;
    font-size: 24px;
    font-family:Georgia, 'Times New Roman', Times, serif
  }
  
  ul {
    text-align: left;
  }
.book {
    padding: 15px 0 0 0;
    margin: auto;
}
.shelf {
    border-bottom: 30px solid #A1A194;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: -15px;
    z-index: -1;
    -webkit-box-reflect: below 8px -webkit-gradient(linear, right top, right bottom, from(transparent), to(rgba(255, 255, 255, 0.4)));
    margin-bottom: 3%;
}
.grid {
  padding-left: 15%;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-auto-flow: column;
}

