.content {
    position: relative;
  }
  
  .content::after {
    content: "";
    background: url('https://risenetwork.net/storage/app/media/RN_Landingpage.png') center;
    background-size: cover;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }

.buttonSpace {
    margin: 10px;
}