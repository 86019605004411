.headerBlock {
    height: 60vh;
    width: 100%;
}

.water {
    background-image: url('https://risenetwork.net/storage/app/media/image_darker.jpg'); background-attachment: fixed;
    background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.trees {
    background-image: url('https://risenetwork.net/storage/app/media/tree-dark.jpg'); background-attachment: fixed;
    background-size: cover;
    background-position: center center;
}

.clouds {
    background-image: url('https://risenetwork.net/storage/app/media/RN_Landingpage.png'); background-attachment: fixed;
    height: 40vh;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    z-index: -1;  
}

.building {
    background-image: url('https://risenetwork.net/storage/app/media/RN_Landingpage.png'); background-attachment: fixed;
    height: 45vh;
    background-size:inherit;
    background-position:bottom;
    opacity: 0.8;
    z-index: -1;  
}

.advertImage {
    width: 100%;
    height: 400px;
    object-fit: fill;
}

.bg-darker {
    background-color: #222629;
}

.img-fill {
    width: 100%;
    height: auto;
}

.bulletin {
    /* background-image: url('https://cdn.pixabay.com/photo/2012/12/24/08/39/backdrop-72250_960_720.jpg'); background-attachment: fixed; */
    background-color: black;
    background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
  background-size: contain;
}

html{
    scroll-behavior: smooth;
}

.iframe-container {
    text-align:center;
    width: 65%;
}

.Cover {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  @import url(https://fonts.googleapis.com/css?family=Alegreya+Sans:300);
.glass{
	/* background styles */
	position: relative;
	display: inline-block;
	padding: 15px 25px;
	background-color:#4085f1; /*for compatibility with older browsers*/
	background-image: linear-gradient(#15a2b8);

	/* text styles */
	text-decoration: none;
	color: rgb(255, 255, 255);
	font-size: 25px;
	font-family: 'Alegreya Sans', sans-serif;
	font-weight: 100;

    border-radius: 3px;
    box-shadow: 0px 1px 4px -2px #333;
    text-shadow: 0px -1px #333;
}

.glass:after{
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: calc(100% - 4px);
	height: 50%;
	background: linear-gradient(rgba(105, 182, 233, 0.8), rgba(255,255,255,0.2));
}

.glass:hover{
	background: linear-gradient(rgb(255, 255, 255),#4085f1);
}

div.center {
    margin-left: auto;
    margin-right: auto;
    display: table;
    color:  white;
    font-size: 24px;
    font-family:Georgia, 'Times New Roman', Times, serif
  }
  
  ul {
    text-align: left;
  }